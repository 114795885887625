angular.module("app").service('_diseasesForecastService', function ($rootScope, _v, _calendar, _view, _apiService, $q, _i18n, _logicService) {

    this.clearChart = function () {
        d3.select("#diseaseGraph").style("display", "none");
        d3.select("#disease-graph-body").selectAll("*").remove();
    };

    this.chart = function (data) {
        var browserWidthPx = document.body.clientWidth;
        var xOffset = 500;
        var xOffset1 = 50;
        var deferred1 = $q.defer();
        var deferred2 = $q.defer();

        d3.select("#diseaseGraph").select(".close-me").on("click", function(){
            _view.setForecastOpened(false);
            d3.select("#diseaseGraph").style("display", "none");
            d3.select("#disease-graph-body").selectAll("*").remove();
        });

        d3.select("#diseaseGraph").select(".disease-name").html(data.disease.formName);
        d3.select("#disease-graph-body").selectAll("*").remove();
        d3.select("#diseaseGraph").style("display", "block");

        var GAP = 20;
        var svg = d3.select("#disease-graph-body").append("svg").attr("width", '100%').attr("height", '100%');
        var heightPx = svg.node().getBoundingClientRect().height;

        for (var i = 0; i <= 4; i++) {
            svg.append('line').attr("x1", GAP).attr("y1", getY(i*25, heightPx, GAP))
                .attr("x2", browserWidthPx - 2*GAP - xOffset - xOffset1)
                .attr("y2", getY(i*25, heightPx, GAP))
                .attr('stroke-width', 1)
                .attr('stroke', 'white');
        }

        _calendar.getDisplayedDateBoundariesPromise().then(function(calendarBoundaries) {
            var from = calendarBoundaries.from;
            var to = calendarBoundaries.to;

            var promisesArray = [];
            _.each(data.forecast, function (e) {
                promisesArray.push(_calendar.getPositionForDatePromise(e.start));
            });
            $q.all(promisesArray).then(function (values) {
                var coordsGreen = [];
                var coordsRed = [];
                _.each(values, function (e, i) {
                    if (!_.isNull(e)) {
                        coordsGreen.push([getX(browserWidthPx, e, xOffset), getY(data.forecast[i].value, heightPx, GAP)]);
                        if (isOverlap(data.forecast[i], data.thresholds)) {
                            coordsRed.push([getX(browserWidthPx, e, xOffset), getY(data.forecast[i].value, heightPx, GAP)]);
                        }
                    }
                });
                svg.append("path").datum(coordsGreen).attr('stroke-width', '4px').attr('stroke', '#7ED321').attr('fill', 'none').attr("d", d3.line());
                svg.append("path").datum(coordsRed).attr('stroke-width', '4px').attr('stroke', '#F56423').attr('fill', 'none').attr("d", d3.line());
                deferred1.resolve(1);
            });

            promisesArray = [];
            var thresholdSegmentsValues = [];
            var thresholdSegmentsIndexes = [];
            var currentSegmentsIndex = -1;
            var needNewSegment = true;

            _.each(data.thresholds, function (it) {
                var s = it.start;
                var e = it.end;
                if (s.isBefore(from) && e.isAfter(from)) {
                    s = from;
                }
                if (s.isBefore(to) && e.isAfter(to)) {
                    e = to;
                }

                if (!_.isNull(it.value)) {
                    if (needNewSegment) {
                        needNewSegment = false;
                        currentSegmentsIndex++;
                    }
                    thresholdSegmentsValues.push(it.value);
                    thresholdSegmentsValues.push(it.value);
                    thresholdSegmentsIndexes.push(currentSegmentsIndex);
                    thresholdSegmentsIndexes.push(currentSegmentsIndex);
                    promisesArray.push(_calendar.getPositionForDatePromise(s));
                    promisesArray.push(_calendar.getPositionForDatePromise(e));
                } else {
                    needNewSegment = true;
                }
            });

            $q.all(promisesArray).then(function (values) {
                var coordsWhite = [];
                var currentSegment = -1;
                _.each(values, function (e, i) {
                    if (!_.isNull(e)) {
                        if (currentSegment != thresholdSegmentsIndexes[i]) {
                            if (_.size(coordsWhite) > 0) {
                                svg.append("path").datum(coordsWhite).attr('stroke-width', '4px').attr('stroke', '#fff').attr('fill', 'none')
                                    .attr('stroke-dasharray', '5,5').attr("d", d3.line());
                            }
                            currentSegment = thresholdSegmentsIndexes[i];
                            coordsWhite = [];
                        }
                        coordsWhite.push([getX(browserWidthPx, e, xOffset), getY(thresholdSegmentsValues[i], heightPx, GAP)]);
                    }
                });
                //last segment
                if (_.size(coordsWhite) > 0) {
                    svg.append("path").datum(coordsWhite).attr('stroke-width', '4px').attr('stroke', '#fff').attr('fill', 'none')
                        .attr('stroke-dasharray', '5,5').attr("d", d3.line());
                }
                deferred2.resolve(1);
            });
            $q.all([deferred1.promise, deferred2.promise]).then(function (values) {
                for (var i = 0; i <= 4; i++) {
                    svg.append('text')
                        .attr('x', browserWidthPx - GAP - xOffset - xOffset1)
                        .attr('y', getY(i*25, heightPx, GAP))
                        .attr('fill', '#fff')
                        .attr('text-anchor', 'middle')
                        .attr('alignment-baseline', 'central')
                        .attr('dominant-baseline', 'central')
                        .text(i*25 +'%');
                }
            });
        });
    };

    //val in percent
    function getY(val, heightPx, gap) {
        return gap + ( (100-val) / 100 * (heightPx - 2 * gap));
    }

    function getX(browserWidthPx, e, xOffset) {
        return _logicService.round((browserWidthPx / 100) * e) - xOffset;
    }

    /**
     * disease threshold chart model
     */
    this.model= function (sowing) {
        var deferred = $q.defer();
        var promises = [];

        promises.push(_apiService.sowingDiseases(_view.getCurrentAccount(), sowing.id));
        promises.push(_apiService.sowingDiseasesThresholds(_view.getCurrentAccount(), sowing.id));
        promises.push(_apiService.getDiseases(_view.getCurrentAccount()));

        $q.all(promises).then(function(r) {
            var result = [];
            _.each(r[0].data, function (value, key) {
                result.push(getDiseaseInfo(key, value, r[1].data, r[2].data));
            });

            deferred.resolve(result);
        });

        return deferred.promise;
    };

    function getDiseaseInfo(diseaseId, values, thresholds, diseases) {
        var result = {disease: _.find(diseases, function (d) { return d.id == diseaseId })};
        result.disease.formName = _i18n.getStringFromBundleLat(result.disease.name);
        result.forecast = _.map(values, function (value, key) { return {start: moment.utc(key), value: value}; });
        var now = moment.utc();
        result.forecast = _.filter(result.forecast, function (x) { return x.start.isSameOrAfter(now)});
        result.thresholds = _.map(thresholds[diseaseId].thresholds, function (e) {return {start: moment.utc(e.startDate), end: moment.utc(e.endDate), value: e.value}; });
        result.thresholdsCustomized = thresholds[diseaseId].customized;
        result.thresholdsOverlap = getOverlap(result.forecast, result.thresholds);
        return result;
    }

    function getOverlap(forecast, thresholds) {
        var result = [];
        var s = null;
        var e = null;
        _.each(forecast, function (f) {
            if (isOverlap(f, thresholds)) {
                if (!s) {
                    s = f.start;
                }
                e = f.start;
            } else {
                if (s && e) {
                    result.push({from: s.clone(), to: e.clone()});
                }
                s = null;
                e = null;
            }
        });
        if (s && e) {
            result.push({from: s.clone(), to: e.clone()});
        }

        return result;
    }

    function isOverlap(v, thresholds) {
        var result = false;
        _.each(thresholds, function (t) {
            if (v.start.isBetween(t.start, t.end, null, '[]')) {
                if (_.isNumber(t.value) && t.value <= v.value) {
                    result = true;
                }
            }
        });

        return result;
    }
});